import React from "react";
import LegalTextBlock from "../components/LegalTextBlock";
import LegalHeading3 from "../components/LegalHeading3";
import LegalHeading2 from "../components/LegalHeading2";
// import LegalList from "../components/LegalList";

function terms(props) {
  return (
    <div className="max-w-5xl py-10 mx-auto text-left">
      <LegalHeading2>Terms and conditions</LegalHeading2>
      <LegalHeading3> Article 1 &ndash; The Platform and the Company </LegalHeading3>
      <LegalTextBlock>
      The Platform accessible at www.ooofer.com and downloadable from the App Store and Android Apps (hereinafter referred to
      as the " <strong> Platform </strong> ") is owned, designed and operated by SRL DAHU PARTNERS (hereinafter referred to as
      the " <strong> Company" </strong> ), registered with the Crossroads Bank for Enterprises under number
      0758.797.445.
      </LegalTextBlock>
      <LegalHeading3> Article 2 &ndash; Definitions </LegalHeading3>
      <LegalTextBlock>
      Capitalized terms used in the present General Terms and Conditions shall have the meanings set out below, whether used
      in the singular or plural, feminine or masculine.<br />
      <br />
      <strong> ARTICLE </strong> : an Article of the present General Terms and Conditions.<br />
      <br />
      <strong> COMPANY </strong> : SRL DAHU PARTNERS, which operates and offers its Services on the Platform.<br />
      <br />
      <strong> DESCRIPTION </strong> : the elements describing a Partner's Provision of Service, notably the texts and photos
      appearing on the Platform.<br />
      <br />
      <strong> GENERAL </strong> <strong> TERMS AND CONDITIONS: </strong> the present General Terms and Conditions of Sale and
      Use which govern the relationship between Subscribers and the Company in the context of the Services offered by the
      latter, as well as the conditions of use of the Platform.<br />
      <br />
      <strong> ORDER/RESERVATION </strong> : any Order or Reservation of a Provision of Service made by a Subscriber on the
      Platform.<br />
      <br />
      <strong> PARTNER </strong> : any of the Company's Hotel Partners who offers a Provision of Service on the
      Platform.<br />
      <br />
      <strong> PLATFORM </strong> : the Platform accessible at www.ooofer.com and downloadable from App Store and Android Apps
      on which the Company offers its Services and the Partners their Prestation of Services.<br />
      <br />
      <strong> PROVISION OF SERVICE </strong> : any Provision of Service (rental or provision of space, hosting, etc.) offered
      on the Platform by the Partners.<br />
      <br />
      <strong> SERVICE </strong> : the booking service of the Provision of Services provided by the Company to Subscribers on
      the Platform.<br />
      <br />
      <strong> SUBSCRIBER </strong> : any natural or legal person who has taken out a subscription with the Company,
      benefiting from its Services and using the Platform in order to book a Provision of Service offered by a Partner and any
      person enjoying the Provision of Service <em> in fine. </em><br />
      </LegalTextBlock>
      <LegalHeading3> Article 3 &ndash; Purpose and scope of the present General Terms and Conditions </LegalHeading3>
      <LegalTextBlock>
      <strong> 3.1. </strong> The present General Terms and Conditions and its appendices aim to exhaustively define the
      contractual relationship between, on the one hand, the Company and, on the other hand, any natural or legal entity
      (hereinafter referred to as the " <strong> Subscriber" </strong> ) wishing to register on the Platform in order to
      benefit from the Company's Services (hereinafter referred to as the " <strong> Services" </strong> ) and from the
      Provision of Services of its Hotel Partners (hereinafter referred to as the " <strong> Provision of Services </strong> "
      and " <strong> Partners" </strong> respectively).<br />
      <br />
      The present General Terms and Conditions and its appendices also aim to exhaustively define the conditions of
      registration and use of the Platform by the Subscriber.<br />
      <br />
      <strong> 3.2. </strong> Registration on the Platform and the use of the associated Services is subject to the
      Subscriber's unconditional and prior acceptance of the present General Terms and Conditions and its appendices.<br />
      <br />
      The Subscriber's full, complete and unreserved acceptance of the present General Terms and Conditions is thus deemed to
      have been acquired prior to the validation of any Reservation made online, as soon as the Subscriber has ticked the box
      "I accept the General Terms and Conditions of Sale" when registering on the Platform.<br />
      <br />
      The Subscriber's adherence to the General Terms and Conditions implies unreserved acceptance of all their
      provisions.<br />
      <br />
      <strong> 3.3. </strong> The Company reserves the right to adapt or modify the present General Terms and Conditions at
      any time. Any modification to the present General Terms and Conditions will come into effect immediately upon
      publication of the new provisions on the Platform or upon communication of the new provisions by e-mail or message on
      the Platform by the Company to the Subscriber, the new modified version being deemed to be expressly accepted by the
      Subscriber.<br />
      <br />
      It is therefore imperative for all Subscribers to read the entirety of the General Terms and Conditions before
      validating any Reservation and it is also recommended that they download and/or print them in order to keep a
      copy.<br />
      <br />
      The General Terms and Conditions applicable to the Subscriber shall be the General Terms and Conditions in force at the
      time of validation of the Subscriber's Reservation on the Platform.<br />
      <br />
      <strong> 3.4. </strong> The present General Terms and Conditions and its appendices and the conditions or warnings
      appearing on the Platform constitute the entirety of the contractual provisions applicable to the relationship between
      the Company and the Subscriber.<br />
      <br />
      They may however be supplemented by special sales conditions appearing on the Description of the Provision of
      Service.<br />
      <br />
      Within the framework of the Subscriber's relationship with the Partners, it is the general and/or special conditions of
      purchase, sale and/or use of the Partners that shall apply.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 4 &ndash; Services of the Company </LegalHeading3>
      <LegalTextBlock>
      The purpose of the Services provided by the Company is to grant and guarantee to the Subscriber a right of access and
      use of the Platform in order to enable him to book the Provision of Services provided by the Partners.<br />
      <br />
      The Platform thus acts as an electronic marketplace on which the Company, as an intermediary, publishes offers of
      Provision of Services from Partners and offers the following main Services:
      <ul>
        <li>the creation of a personalized page specific to each Partner's establishment on the Platform, accompanied by a
          Description containing all relevant information concerning this establishment and the Provision of Service offered
          by the Partner;</li>
        <li>the management of Reservations by Subscribers, on the Platform, of individual work spaces in the common areas
          (lounge, lobby, restaurant, library, etc.) of the Partner's facilities, according to an inventory including the
          availability and schedules defined by the Partner, and the notification of said Reservations to the Partner;</li>
        <li>the management of Reservations by Subscribers, on the Platform, of private meeting rooms and work rooms in the
          Partner's facilities and the notification of said Reservations to the Partner;</li>
        <li>the management of Reservations by Subscribers on the Platform of rooms (overnight stays) in the Partner's
          facilities through the Partner's own reservation management software (Channel Manager), which automatically manages
          prices and availability in real time.</li>
      </ul>
      </LegalTextBlock>
      <LegalHeading3> Article 5 &ndash; Access to the Services, creation of the account and subscription </LegalHeading3>
      <LegalTextBlock>
      <strong> 5.1. Access to the Platform and Services </strong><br />
      <br />
      Any person can access the Platform and consult the Partners' Provision of Service offers and their Descriptions, both
      via the website www.ooofer.com and via the application downloadable from the App Store and Android Apps.<br />
      <br />
      The Company's Services and the booking of Partners' Provision of Services are however exclusively reserved for the
      Company's Subscribers, after creating an account, validating the General Terms and Conditions and paying the
      subscription.<br />
      <br />
      <strong> 5.2. Creation of the account </strong><br />
      <br />
      Any person wishing to benefit from the Company's Services and, therefore, to become a Subscriber must first register on
      the Platform and create an account by entering a valid e-mail address and a password.<br />
      <br />
      The person is then invited to create a profile (by entering his/her first and last name(s), date of birth, postal
      address and mobile number) and to confirm that he/she has read and accepts the present General Terms and Conditions. An
      e-mail and/or SMS will then be sent to him/her with a six-digit code to verify the e-mail address provided. Once the
      person enters the verification code within the time and space specified, the account is validated.<br />
      <br />
      After validation of the account, the person fills in other information to specify his/her profile, such as the field in
      which he/she works, his/her professional status, his/her needs and expectations in terms of teleworking, the places
      where he/she would like to telework, etc.<br />
      <br />
      <strong> 5.3. Refusals and limitations of registrations </strong><br />
      <br />
      The Company reserves the right to limit the number of persons on the Platform and, therefore, to refuse a registration
      request, at its sole discretion and without any obligation to give reasons. In order to guarantee a quality Service, the
      Company wishes in particular not to exceed a certain ratio of Subscribers compared to the number of Partners,
      establishments and Provision of Services offered on the Platform. In the event that this ratio is exceeded, the Company
      will create a waiting list.<br />
      <br />
      <strong> 5.4. Choice of subscription </strong><br />
      <br />
      Once the account has been created, prior to the Reservation of a first Provision of Service, the person chooses the
      subscription formula that interests him/her from among the different options offered.<br />
      <br />
      Once the subscription formula and the duration of the commitment have been chosen, the person fills in the data relating
      to the means of payment (bank card, credit card, MasterCard, etc.) and pays in accordance with the provisions of Article
      8 of the present General Terms and Conditions.<br />
      <br />
      <strong> 5.5. Modification of the subscription </strong><br />
      <br />
      If the Subscriber decides to change his subscription formula, the new subscription formula chosen will take effect
      immediately.<br />
      <br />
      <strong> 5.6. Tacit renewal of the subscription </strong><br />
      <br />
      When a Subscriber takes out a subscription, he/she agrees to the tacit renewal of this subscription at the end of its
      term, under the same conditions and for the same duration.<br />
      <br />
      However, he/she may specify that he/she renounces this renewal before the said term, by spontaneously cancelling it in
      the space provided for this purpose on the Platform.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 6 &ndash; Reservation of a Provision of Service </LegalHeading3>
      <LegalTextBlock>
      <strong> 6.1. </strong> The Subscriber may only make a Reservation for a Provision of Service if he/she is in order of
      payment for his/her subscription.<br />
      <br />
      <strong> 6.2 </strong> . The possibilities of making a Reservation of a Provision of Service in a Partner's facilities
      are limited according to the places, availability and times defined by the Partner.<br />
      <br />
      <strong> 6.3. </strong> By reserving a Provision of Service, the Subscriber confirms that he/she has read and accepts
      the present General Terms and Conditions as well as the general and/or specific conditions of purchase, sale and/or use
      of the Partner in whose establishment the Provision of Service is reserved. <br />
      <strong> 6.4. Reservation of an individual work space in the common areas of the Partner's facilities </strong><br />
      <br />
      The Subscriber, regardless of the subscription formula chosen, may reserve individual work spaces in the common areas of
      the Partners' facilities on an unlimited basis and at no additional cost, subject to the provisions of Article 6.2. of
      the present General Terms and Conditions. <br />
      In the event of force majeure or if, exceptionally, the Partner is unable to accommodate the Subscriber for any reason
      whatsoever, the Subscriber will be informed by e-mail by the Company within 24 hours of the Reservation.<br />
      <br />
      <strong> 6.5. Reservation of a private meeting or work room in the Partner's facilities </strong><br />
      <br />
      The Subscriber, regardless of the subscription formula chosen, may reserve private meeting or work rooms in the
      Partners' facilities on an unlimited basis, subject to the provisions of Article 6.2. of these General Terms and
      Conditions.<br />
      <br />
      The Reservation of a private meeting or work room in the Partner's facilities is subject to payment of the price
      indicated in the Description or the offer of the Provision of Service, in accordance with the terms and conditions set
      out in Article 8 of the present General Terms and Conditions.<br />
      <br />
      Following the Reservation made by the Subscriber on the Platform, the Company notifies the Partner who has a period of 4
      (four) hours to validate the Reservation according to the information submitted by the Subscriber, provided that the
      Reservation is made between 8:00 am and 6:00 pm depending on the time zone of the Partner's establishment. If the
      Reservation is made outside of this time slot, the time period will start from the beginning of the same time slot on
      the next working day. The Company then informs the Subscriber of the acceptance or refusal of the Reservation by the
      Partner.<br />
      <br />
      <strong> 6.6. Reservation of a room (overnight stay) in the Partner's facilities </strong><br />
      <br />
      The Subscriber, regardless of the subscription formula chosen, may make unlimited reservations for rooms (overnight
      stays) in the Partners' facilities, subject to the provisions of Article 6.2. of these General Terms and
      Conditions.<br />
      <br />
      The Reservation of a room (overnight stay) in the Partner's facilities is subject to payment of the price indicated in
      the Description or the offer of the Provision of Service, in accordance with the terms and conditions set out in Article
      8 of the present General Conditions.<br />
      <br />
      <strong> 6.7. </strong> The offers of Provision of Services available on the Platform may not be negotiated under any
      circumstances, neither with the Company nor with the Partner.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 7 &ndash; Prices </LegalHeading3>
      <LegalTextBlock>
      <strong> 7.1. </strong> The prices indicated on the Platform are in euros (EUR) and are understood to include all taxes,
      unless expressly stated otherwise on the Platform, and exclude any local taxes, visa and/or bank card fees, etc., which
      remain the responsibility of the Subscriber.<br />
      <br />
      <strong> 7.2. </strong> Where necessary, the Descriptions and Provision of Services offers presented on the Platform
      specify the price applicable to each Partner's Provision of Service. The prices of the different subscription formulas
      are also indicated on the Platform. The Company reserves the right to modify the prices at any time but at the latest at
      the time of the Order. The applicable prices are those in force on the day of the said Order.<br />
      <br />
      <strong> 7.3. </strong> When the Subscriber takes out a subscription or makes a Reservation for a Provision of Service,
      the Subscriber agrees to pay the price indicated, including all fees and taxes that may apply.<br />
      <br />
      <strong> 7.4. </strong> In general, and unless expressly mentioned otherwise on the Platform, the prices do not include
      all expenses of a personal nature or ancillary to the Provision of Service (in particular and, if applicable, parking
      fees, telephone, drinks, food, room service, tips, massages, access to wellness facilities and spas, etc.) and more
      generally any service not expressly mentioned on the Platform. These expenses will be invoiced separately, directly by
      the Partner.<br />
      <br />
      <strong> 7.5. </strong> Obvious pricing errors on the Platform and printing errors are not binding.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 8 &ndash; Terms and conditions of payment </LegalHeading3>
      <LegalTextBlock>
      <strong> 8.1. Terms and conditions of payment of the subscription </strong><br />
      <br />
      Payment of the subscription is made online on the Platform either by payment card (Visa, American Express, Mastercard,
      etc.), or by bank transfer, or by any other means of payment offered by the Company. No other means of payment is
      accepted.<br />
      <br />
      Payment for the subscription is made via Apple or Google. The Company has no control over these payments or
      transactions, nor does it have the ability to cancel them.<br />
      <br />
      The Company shall have no liability to Subscribers or to any third party for any claims or damages that may arise from
      payments or transactions made through the Platform or from any other payment or transaction that the Subscriber has made
      through Apple or Google.<br />
      <br />
      The Company is in no way responsible for any problems that the Subscriber may encounter during transactions with the
      various payment companies.<br />
      <br />
      Subject to the provisions of Article 10 of the present General Terms and Conditions, the Company does not provide any
      refund for payments or transactions that the Subscriber makes on or via the Platform. <br />
      <strong> 8.2. Terms and conditions of payment of the Provision of Services </strong><br />
      <br />
      8.2.1. Payment for a private meeting or work room is made directly to the Partner, in accordance with the payment terms
      and conditions provided by the Partner.<br />
      <br />
      8.2.2. Payment for a room (overnight stay) is made directly to the Partner, in accordance with the payment terms and
      conditions provided by the Partner.<br />
      <br />
      8.2.3. If the Partner arranges the payment, he is responsible for the handling of this payment and the completion of the
      transaction. Its general terms and conditions therefore apply and the Company has no influence on these and is not
      responsible for them. Before making a Reservation, the Subscriber shall check the Partner's own payment terms and
      conditions.<br />
      <br />
      8.2.4. The Company does not accept any payments or financial transactions on behalf of the Partner via the Platform. The
      Company only provides a system to facilitate Reservations of Provision of Services directly with the Partner. The
      Company cannot be held responsible for any problems in the payment process.<br />
      <br />
      The Company cannot be held responsible for the consequences of connection problems between the Platform and the
      Partner's own Channel Manager software.<br />
      <br />
      The Company is in any case not responsible for any booking fees applied by the Partner's own reservation management
      software (Channel Manager).<br />
      <br />
      <strong> 8.3. Fraud </strong><br />
      <br />
      If the Subscriber is aware of fraud or unauthorized use of his means of payment or suspects such behaviour, it is his
      responsibility to contact his payment provider, who may cover the resulting costs, sometimes by deducting an
      excess.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 9 &ndash; Conditions for cancelling a Reservation of a Provision of Service </LegalHeading3>
      <LegalTextBlock>
      <strong> 9.1. Conditions for cancelling a Reservation </strong> <strong> of an individual work space in the common areas
        of the Partner's facilities </strong><br />
      <br />
      The Subscriber is free to cancel a Reservation for an individual work space in the common areas of the Partner's
      facilities without time limit and without charge.<br />
      <br />
      Nevertheless, in the event of abusive behaviour by the Subscriber (for example, if the Subscriber makes numerous
      last-minute cancellations or never turns up for his or her Reservations and privatizes places for nothing), the Company
      reserves the right to temporarily block the Subscriber's account, at its own discretion and for the time it deems
      necessary.<br />
      <br />
      <strong> 9.2 Conditions for cancelling a Reservation </strong> <strong> of a private meeting or work room in the
        Partner's facilities </strong><br />
      <br />
      In the event of cancellation of a Reservation of a private meeting or work room in the Partner's facilities, the
      Subscriber:
      <ul>
        <li>shall not have to pay anything if the cancellation is made at least 72 hours before the start time of the
          Provision of Service;</li>
        <li>shall have to pay half of the price if this cancellation is made between 72 hours and 48 hours before the start
          time of the Provision of Service;</li>
        <li>shall have to pay the full price if the cancellation is made less than 48 hours before the start time of the
          Provision of Service.</li>
      </ul>
      <strong> 9.3. Conditions for cancelling </strong> <strong> a Reservation of a room (overnight stay) in the Partner's
        facilities </strong><br />
      <br />
      The cancellation conditions applicable to the Reservation of a room (overnight stay) are those specific to the Partner
      in whose establishment the room has been booked.<br />
      <br />
      If the Subscriber cancels the Reservation of a room (overnight stay) or does not show up, the cancellation or no-show
      fee and any refund will therefore depend on the Partner's cancellation or no-show policy.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 10 &ndash; Right of withdrawal </LegalHeading3>
      <LegalTextBlock>
      Any Subscriber who can be considered a "consumer" may exercise a right of withdrawal, without having to give reasons and
      free of charge, within 14 days of the conclusion of the service contract (i.e. taking out a subscription or booking a
      Provision of Service), except if:
      <ul>
        <li>the service was fully executed within this period;</li>
        <li>execution has begun with the express prior consent of the Subscriber within this period;</li>
        <li>the service consists of accommodation (other than for residential purposes) and the contract provides for a
          specific date or period of performance.</li>
      </ul>
      Any Subscriber who can be considered an "enterprise" is not entitled to this right of withdrawal.<br />
      <br />
      In cases where the Subscriber cannot invoke the right of withdrawal, the Services ordered on the Platform are
      exclusively subject to the conditions of cancellation set out in the present General Terms and Conditions and/or the
      Partner's own terms and conditions.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 11 &ndash; Conditions of use of the Platform and Subscriber's responsibility </LegalHeading3>
      <LegalTextBlock>
      <ul>
        <li><strong> <u> Use of the Platform </u> </strong></li>
      </ul>
      <strong> 11.1. </strong> The Service is reserved for Subscribers of legal age (over 18). The Company cannot accept the
      registration of a minor who is not accompanied by an adult. Consequently, the Company cannot be blamed for not
      performing the agreed services in the event that, despite this prohibition, an unaccompanied minor registers on the
      Platform without the knowledge of the Company. Such an act would be considered as a fault of the Subscriber exonerating
      the Company from its responsibility.<br />
      <br />
      <strong> 11.2. </strong> The Subscriber must necessarily have a valid e-mail address and must ensure that his or her
      e-mails are received correctly.<br />
      <br /> If the Subscriber transmits erroneous contact details at any stage of the registration process, the Company shall
      not be held liable. Acceptance of the present General Terms and Conditions implies express and irrevocable acceptance of
      the use of the electronic medium as a means of communication and conclusion of the contract between the Subscriber and
      the Company. In this respect, the Subscriber is solely responsible for the regular consultation of his/her e-mail
      box.<br />
      <br />
      <strong> 11.3 </strong> Except in the case of fraud, for which it is up to the Subscriber to provide proof, the
      Subscriber is financially responsible for his or her actions on the Platform, in particular for the use that will be
      made of his or her user name and password. The Subscriber also guarantees the truthfulness and accuracy of the
      information provided by him/her on the Platform.<br />
      <br />
      Fraudulent use of the Platform, or use that contravenes the present General Terms and Conditions, will justify the
      Subscriber being denied access to the Services at any time.<br />
      <br />
      The Subscriber declares that he/she will use the Platform in accordance with the present General Terms and Conditions,
      in his/her own name and/or in the name and on behalf of any final beneficiary of the Services, for whom he/she expressly
      acknowledges that he/she is the authorized representative. The present General Terms and Conditions are binding on the
      Subscriber and on any final beneficiary of the Services.
      <ul>
        <li><strong> <u> Relations with Partners </u> </strong></li>
      </ul>
      <strong> 11.4. </strong> The Subscriber undertakes to make reasonable use of the common areas of the Partner's
      facilities and, where applicable, the room or private work or meeting room made available by the Partner. Any behaviour
      contrary to the principles of safety and/or hygiene, good morals and/or public order may lead the Partner to ask the
      Subscriber to leave the establishment immediately without any compensation and/or without any refund if payment has
      already been made.<br />
      <br />
      Any damage caused by the Subscriber in the common areas of the Partner's facilities or in a room or a meeting or work
      room made available to the Subscriber may be invoiced directly by the Partner for the amount of the damage.<br />
      <br />
      Any item missing from the room or the private meeting or work room booked by the Subscriber, duly noted by the Partner,
      will be invoiced directly to the Subscriber up to its value.<br />
      <br />
      <strong> 11.5. </strong> The Subscriber undertakes to respect the timetables imposed by the Company and the Partners and
      to arrive at the time set for the Reservation.<br />
      <br />
      If the Subscriber believes that he will not arrive on time, it is his responsibility to contact the Partner to indicate
      his arrival time so that he does not cancel his/her Reservation.<br />
      <br />
      If the Subscriber arrives late, the Company is in no way responsible for the consequences of this situation (e.g.
      cancellation of the Reservation or costs that the Partner may charge).<br />
      <br />
      If the Subscriber does not show up for a Reservation of a private meeting or work room or a room (overnight stay), this
      Reservation will be considered to have been tacitly cancelled by the Subscriber, who will owe the Partner the price of
      the Provision of Service.<br />
      <br />
      <strong> 11.6 </strong> As the person making the Reservation, the Subscriber is responsible for the actions and
      behaviour (in relation to the Provision of Service) of each member of the group accompanying him/her (for example, in
      the case of the Reservation of a private meeting room or room).
      <ul>
        <li><strong> <u> Measures against unacceptable behaviour </u> </strong></li>
      </ul>
      <strong> 11.7. </strong> The Company reserves the right to warn the Subscriber, to prevent the Subscriber from making
      Reservations, to cancel any Reservation that the Subscriber has already made and/or to prevent the Subscriber from using
      the Platform if the Company considers that there is a good reason to do so, such as, for example, in the following cases
      <ul>
        <li>fraud or abuse ;</li>
        <li>non-compliance with the present General Terms and Conditions or with the general and/or special conditions of
          purchase, sale and/or use specific to the Partners and/or with the laws or regulations in force;</li>
        <li>inappropriate or illegal behaviour (e.g. violence, threats or invasion of privacy) towards the Company, a Partner
          or any other person.</li>
      </ul>
      If the Company cancels a Reservation, deletes a subscription or permanently deregisters a Subscriber from the Platform
      for these reasons, the Subscriber will not be entitled to any reimbursement or compensation, without recourse.<br />
      <br />
      <strong> 11.8. </strong> In the event that the Subscriber fails to comply with the terms of his or her Reservation or
      violates the cancellation policy applicable to the latter, the Company is entitled to communicate the Subscriber's bank
      details at the request of the Partner.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 12 &ndash; Obligations and responsibilities of the Company </LegalHeading3>
      <LegalTextBlock>
      <strong> 12.1. </strong> Except where mandatory consumer protection legislation applies, the Company has no obligations
      other than those set out in the present General Terms and Conditions and its appendices.<br />
      <br />
      <strong> 12.2. </strong> The Company only acts as an intermediary between the Partner and the Subscribers.<br />
      <br />
      Once a Reservation is made by a Subscriber with a Partner via the Platform, a contract is formed directly between this
      Subscriber and the Partner, to which the Company is not a party. The Partner is solely responsible for the
      Service.<br />
      <br />
      In this context, the general and/or special terms and conditions of sale and/or use, internal regulations and any other
      relevant document specific to the Partner shall apply in its relationship with the Subscriber.<br />
      <br />
      The Company is in no way responsible for the application or enforceability of these general and/or special conditions of
      sale and/or use, internal regulations or any other relevant document specific to the Partner against the
      Subscriber.<br />
      <br />
      It is indeed up to the Partner to personally ensure the communication and enforceability of its own general and/or
      special terms and conditions of sale and/or use, internal regulations and any other relevant document against the
      Subscriber.<br />
      <br />
      The terms and conditions of access, refusal of access and use of the Partner's facilities, as well as the terms and
      conditions of booking, payment, cancellation, refund, etc. are the sole responsibility of the Partner.<br />
      <br />
      <strong> 12.3. </strong> The Company takes reasonable steps to make the Platform available to Subscribers. However, it
      does not guarantee that all the information it contains is accurate (it obtains some of this information from
      Partners).<br />
      <br />
      In particular, the information and media forming the basis of the Descriptions and the content of the pages specific to
      the Partner's establishments on the Platform as well as the offers of Provision of Services proposed are provided to the
      Company by the Partner. The Company cannot therefore guarantee to Subscribers the realistic and recent nature of the
      information and media provided. The photos and illustrations used are not contractual and shall in no case constitute an
      essential element of the contract. Indeed, changes may have occurred between the date when the photograph or
      illustration was taken and the date of the Subscriber's Reservation. Thus, there may be slight variations between the
      photos and illustrations appearing on the Platform and the actual situation of the facilities.<br />
      <br />
      To the extent permitted by law, the Company shall not be liable for any errors or missing information on the Platform,
      although it will use its best endeavors to correct them as soon as possible. <br />
      <strong> 12.4. </strong> The Company does not make any promises concerning the Provision of Services of the Partners,
      except as expressly stated in the present General Terms and Conditions. Making the right choice is the sole
      responsibility of the Subscriber.<br />
      <br />
      <strong> 12.5. </strong> The Company may not be involved in any way or be held liable in the event of a dispute between
      a Partner and a Subscriber.<br />
      <br />
      <strong> 12.6. </strong> The Company is in no way responsible for the consequences and direct or indirect damage
      resulting from any incident occurring within the Partner's establishment and involving a Subscriber or a member of his
      group following a Reservation made on the Platform.<br />
      <br />
      Any incident involving a Subscriber or a member of his group occurring within the Partner's establishment or within the
      framework of the contractual relationship between the Partner and the Subscriber is governed by the Partner's own
      general and/or special terms and conditions of sale and/or use, internal regulations and any other relevant document and
      is the sole responsibility of the Partner.<br />
      <br />
      <strong> 12.7. </strong> The Company cannot be held responsible if a Partner cancels a Reservation made by a Subscriber
      in its establishment and no compensation can be claimed by the Subscriber from the Company in this respect.<br />
      <br />
      <strong> 12.8. </strong> The Company cannot be held responsible for (attempted) infringements and/or fraud committed by
      a Subscriber, Partner or any third party via the Platform.<br />
      <br />
      <strong> 12.9. </strong> In order to guarantee its Services, the Company ensures that the Platform is available to the
      Subscriber all year round, 7 days a week, 24 hours a day.<br />
      <br />
      However, the Company does not give any guarantee to the Subscriber regarding:
      <ul>
        <li>the absence of anomalies, errors, bugs, viruses or other malicious software that could affect the navigation on
          the Platform or the implementation of any functionality offered on the Platform;</li>
        <li>the possibility of correcting these anomalies, errors or bugs or of countering these viruses and other malicious
          software;</li>
        <li>the absence of any interruption or failure in the operation of the Platform;</li>
        <li>the possible compatibility of the Platform with a particular hardware or configuration.</li>
      </ul>
      In this context, the Company reserves the right to suspend access to and use of the Platform and its contents for
      technical reasons (in order to ensure its maintenance or update) or for security reasons or for any other reason.<br />
      <br />
      In no event shall the Company be liable for any direct or indirect and/or non-material, foreseeable or unforeseeable
      damages (including loss of profits or opportunity) arising out of the provision and/or use of or total or partial
      inability to use the features of the Platform.<br />
      <br />
      The hypertext links present on the Platform and allowing Subscribers to be directed to other Internet sites are intended
      solely to facilitate Subscribers' research. The Company shall not control the content of these websites nor be held
      responsible for their content.<br />
      <br />
      In any event, the Subscriber declares to be of the characteristics and limits of the Internet, in particular its
      technical performance, the response times for consulting, querying or transferring data and the risks related to the
      security of communications.<br />
      <br />
      <strong> 12.10. </strong> Where applicable, the Subscriber may be protected by mandatory consumer protection laws and
      regulations, which guarantee him rights that no terms and conditions can replace. In this case, the Company's liability
      is determined not only by the present General Terms and Conditions but also by the applicable consumer protection laws
      and regulations.<br />
      <u> </u><br />
      </LegalTextBlock>
      <LegalHeading3> Article 13 &ndash; Force majeure </LegalHeading3>
      <LegalTextBlock>
      Neither Party may be held responsible for the total or partial non-performance of its obligations if such
      non-performance is due to an act of God or to the occurrence of an event constituting force majeure.<br />
      <br />
      For the purposes of the present General Terms and Conditions, force majeure shall be understood to mean any
      unforeseeable and unavoidable fact or event outside the control of the Parties which constitutes an insurmountable
      obstacle to the fulfilment of their obligations, including, but not limited to, armed conflicts, fires, storms, floods,
      earthquakes, cyclones, volcanic eruptions or any other natural disaster, severe epidemics, lack of raw materials, lack
      of (sufficient) electricity or natural gas, network failures, partial or total strike, and so on.<br />
      <br />
      The Party affected by such facts or events shall inform the other Party as soon as possible.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 14 &ndash; Complaints </LegalHeading3>
      <LegalTextBlock>
      In the event of a question or complaint, the Subscriber may contact the Company's customer service department by sending
      an e-mail to info@ooofer.com.<br />
      <br />
      The Company endeavors to resolve disputes internally and is under no obligation to submit to alternative dispute
      resolution procedures administered by independent providers.<br />
      <br />
      The Company can assist the Subscriber in communicating with the Partner but cannot guarantee that the Partner will read
      or respond to the information submitted.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 15 &ndash; Intellectual property </LegalHeading3>
      <LegalTextBlock>
      The Platform, as well as the software, databases, texts, information, analyses, images, photographs, graphics,
      trademarks, logos, models, sounds or any other elements contained on the Platform remain the exclusive property of the
      Company and/or of the Partners with whom the Company has entered into licence agreements.<br />
      <br />
      The Subscriber undertakes not to reproduce, exploit, download, redistribute or use in any way whatsoever, even
      partially, these elements, whatever their nature (graphic, textual, audiovisual, sound or software content), without the
      prior consent of the Company or the holders of the trademarks or rights attached to these elements.<br />
      <br />
      The Subscriber shall refrain from introducing, by any means whatsoever, data likely to modify or damage the content or
      presentation of the Platform.<br />
      <br />
      Any infringement of the Company's intellectual property rights by the Subscriber will give rise to legal proceedings and
      the payment of damages for a minimum amount of EUR 500.00, without prejudice to the Company's right to obtain full
      compensation for its actual damage if it is greater.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 16 &ndash; Personal data protection and cookies </LegalHeading3>
      <LegalTextBlock>
      The protection of personal data is the subject of a Privacy Policy as well as a Cookies Policy, both available on the
      Company's Platform.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 17 &ndash; Severability &ndash; Independence of provisions </LegalHeading3>
      <LegalTextBlock>
      <strong> 17.1. </strong> The possible invalidity or unenforceability of any provision of the present General Terms and
      Conditions shall not affect the validity or enforceability of the remaining provisions.<br />
      <br />
      Any provision of the present General Terms and Conditions that is found invalid or unenforceable shall be replaced by
      the Parties with a provision having equivalent economic effect. The Party which finds a provision invalid or
      unenforceable shall inform the other Party as soon as possible.<br />
      <br />
      <strong> 17.2. </strong> The fact that the Company does not avail itself of any provision of the present General Terms
      and Conditions shall not be construed as a waiver of its right to do so at a later date.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 18 - Assignment </LegalHeading3>
      <LegalTextBlock>
      Neither Party shall have the right to assign or transfer, either totally or partially, the rights and obligations
      arising from the present General Terms and Conditions to a third-party without the prior written consent of the other
      Party.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 19 - Miscellaneous </LegalHeading3>
      <LegalTextBlock>
      <strong> 19.1. </strong> The notifications provided for in the present General Terms and Conditions are validly made by
      the Company to the email address communicated by the Subscriber when registering on the Platform.<br />
      <br />
      <strong> 19.2. </strong> Unless there is an obvious error on the part of the Company or its Partners, which the
      Subscriber can prove, the data kept in the Company's and/or its Partners' information systems have evidential value with
      regard to Reservations made by the Subscriber.<br />
      <br />
      Data on computer or electronic media constitute valid evidence and as such are admissible under the same conditions and
      with the same probative force as any document drawn up, received or kept in writing.<br />
      </LegalTextBlock>
      <LegalHeading3> Article 20 - Applicable law and jurisdiction </LegalHeading3>
      <LegalTextBlock>
      <strong> 20.1. </strong> To the extent permitted by binding local and/or European legislation (e.g. on consumer
      protection), the present General Terms and Conditions and the Company's Services shall be governed by and construed in
      accordance with Belgian law, regardless of the Subscriber's country of residence.<br />
      <br />
      <strong> 20.2. </strong> To the extent permitted by binding local and/or European legislation (e.g. on consumer
      protection), any dispute or litigation arising from the validity, interpretation and/or execution of the present General
      Terms and Conditions and the Company's Services shall fall under the exclusive jurisdiction of the French-speaking
      courts of the judicial district of Brussels.<br />
      <br />
      The Parties shall attempt to resolve amicably any difference or dispute arising from the validity, interpretation and/or
      execution of the present General Terms and Conditions and the Company&rsquo;s Services before instituting any legal
      proceedings, although this provision may not be interpreted as a condition for the admissibility of a legal claim.<br />
      </LegalTextBlock>
    </div>
  );
}

export default terms;
